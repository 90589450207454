import { Route } from "nextjs-routes";
import { UserContextState } from "@src/contexts/UserContext";
import {
  LibraryItemType,
  SharingPermission,
  UserType,
} from "@src/types.generated";
import { gql } from "@apollo/client";
import { CoverImageFragment } from "@src/fragments.generated";
import {
  LibraryItemTitleFragment,
  LibraryItemUrlFragment,
} from "@src/components/contentLibrary/utils.generated";
import {
  ContentType,
  LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE,
} from "@src/deprecatedDesignSystem/components/ContentAvatar";
import {
  LibraryContainerLibraryItemFragment,
  LibraryContentSharingPermissionFragment,
  LibraryUserSharingPermissionFragment,
} from "@src/components/library/LibraryPaginatedTable.generated";

export const getCourseDetailRoute = (
  courseId: number,
  courseInstanceId?: number,
  tab?: string,
): Route => {
  const query = {
    id: courseId.toString(),
  };
  if (courseInstanceId) {
    Object.assign(query, { courseInstanceId: courseInstanceId.toString() });
  }
  if (tab) {
    Object.assign(query, { tab });
  }
  return {
    pathname: "/courses/[id]",
    query,
  };
};

export const getPathDetailRoute = (
  pathId: number,
  pathInstanceId?: string,
  tab?: string,
): Route => {
  const query = {
    id: pathId.toString(),
  };
  if (pathInstanceId) {
    Object.assign(query, { pathInstanceId });
  }
  if (tab) {
    Object.assign(query, { tab });
  }
  return {
    pathname: "/paths/[id]",
    query,
  };
};

export const getFileDetailRoute = (fileId: string): Route => {
  return {
    pathname: "/files/[id]",
    query: {
      id: fileId.toString(),
    },
  };
};

export const getSkillDetailRoute = (skillId: number): Route => {
  return {
    pathname: "/skills/[id]",
    query: {
      id: skillId.toString(),
    },
  };
};

export const getLibraryItemEditedAt = (
  x: LibraryContainerLibraryItemFragment,
): string | undefined => {
  if (x.orgSharpConfig) {
    return x.orgSharpConfig.lastEditedAt || x.orgSharpConfig.createdAt;
  } else if (x.orgPremiumContentConfig) {
    return (
      x.orgPremiumContentConfig.lastEditedAt ??
      x.orgPremiumContentConfig.createdAt
    );
  } else {
    return x.lastEditedAt || x.createdAt;
  }
};

export const getLibraryItemArchivedAt = (
  x: LibraryContainerLibraryItemFragment,
): string | undefined => {
  if (x.orgPremiumContentConfig || x.orgSharpConfig) {
    return undefined;
  }
  return x.archivedAt || undefined;
};

export const getPremiumLibraryItemUrl = (
  item: LibraryItemUrlFragment,
): Route | undefined => {
  if (
    item.orgPremiumContentConfig &&
    item.orgPremiumContentConfig.premiumContentConfig.catalogPath?.path.id
  ) {
    return getPremiumPathRoute(
      item.orgPremiumContentConfig.premiumContentConfig.catalogPath
        .id as string,
    );
  } else if (item.orgSharpConfig) {
    return SHARP_DETAIL_ROUTE;
  }
};

export const SHARP_DETAIL_ROUTE: Route = {
  pathname: "/sexual-harassment-prevention",
};

export const getPremiumPathRoute = (catalogPathId: string): Route => {
  return {
    pathname: "/premium-path/[id]",
    query: {
      id: catalogPathId.toString(),
    },
  };
};

export const getPremiumPathPreviewRoute = (catalogPathId: string): Route => {
  return {
    pathname: "/paths/[id]/preview",
    query: {
      id: catalogPathId.toString(),
    },
  };
};

export const userHasEditPermissions = (
  sharingPermissions: LibraryContentSharingPermissionFragment,
  userContext: UserContextState,
  contentOwnerId?: number | undefined,
): boolean => {
  if (userContext.isStaff) {
    return true;
  }
  const userPermission = sharingPermissions.individuals.find(
    (i: LibraryUserSharingPermissionFragment) =>
      i.user.id === userContext.user?.id,
  );
  if (contentOwnerId && contentOwnerId === userContext.user?.id) {
    return true;
  }
  if (userPermission?.permission === SharingPermission.Edit) {
    return true;
  }
  if (
    userContext.user?.userType == UserType.Admin &&
    sharingPermissions.allAdmins == SharingPermission.Edit
  ) {
    return true;
  }
  if (
    userContext.user?.userType == UserType.Manager &&
    sharingPermissions.allManagers == SharingPermission.Edit
  ) {
    return true;
  }
  return false;
};

export const isSpecialContent = (
  x: LibraryContainerLibraryItemFragment,
): boolean => {
  return Boolean(x.orgSharpConfig || x.orgPremiumContentConfig);
};

export const getLibraryItemTitle = (x: LibraryItemTitleFragment): string => {
  if (x.type === LibraryItemType.Path) {
    return x.name.en || "Untitled Module";
  } else if (x.type === LibraryItemType.Course) {
    return x.name.en || "Untitled Course";
  } else if (x.type === LibraryItemType.Skill) {
    return x.name.en || "Untitled Check-In";
  } else if (x.type === LibraryItemType.Task) {
    return x.name.en || "Untitled Task List";
  } else if (x.type === LibraryItemType.TrainingResource) {
    return x.name.en || "Untitled Resource";
  } else if (x.orgSharpConfig) {
    return "Sexual Harassment Prevention Training";
  } else if (x.orgPremiumContentConfig) {
    return (
      x.orgPremiumContentConfig.premiumContentConfig.catalogPath?.path
        .libraryItem.name.en || "Untitled Module"
    );
  }

  return "";
};

export const getLibraryItemType = (x: LibraryItemTitleFragment): string => {
  if (
    x.type === LibraryItemType.Path ||
    x.orgPremiumContentConfig ||
    x.orgSharpConfig
  ) {
    return "Module";
  } else if (x.type === LibraryItemType.Course) {
    return "Course";
  } else if (x.type === LibraryItemType.Skill) {
    return "Check-in";
  } else {
    return "Resource";
  }
};

export const getLibraryItemContentType = (x: LibraryItemType): ContentType => {
  return LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE[x];
};

export const getLibraryItemSharingPermissions = (
  x: LibraryContainerLibraryItemFragment,
): LibraryContentSharingPermissionFragment | null => {
  return x.sharingPermissions;
};

export const getLibraryItemOwnerName = (
  x: LibraryContainerLibraryItemFragment,
): string | undefined => {
  let owner: string | undefined = undefined;
  if (x.orgPremiumContentConfig) {
    owner = x.orgPremiumContentConfig.createdBy?.name;
  } else if (x.orgSharpConfig) {
    owner = x.orgSharpConfig.createdBy?.name;
  } else {
    owner = x.createdBy?.name;
  }
  return owner;
};

export const getLibraryItemOwnerId = (
  x: LibraryContainerLibraryItemFragment,
): number | undefined => {
  let owner: number | undefined = undefined;
  if (x.orgPremiumContentConfig) {
    owner = x.orgPremiumContentConfig.createdBy?.id;
  } else if (x.orgSharpConfig) {
    owner = x.orgSharpConfig.createdBy?.id;
  } else {
    owner = x.createdBy?.id;
  }
  return owner;
};

gql`
  fragment LibraryItemUrl on LibraryItem {
    id
    publishState
    path {
      id
      publishedVersion {
        id
      }
      draftVersion {
        id
      }
    }
    orgPremiumContentConfig {
      id
      premiumContentConfig {
        id
        catalogPath {
          id
          path {
            id
          }
        }
      }
    }
    course {
      id
    }
    trainingResource {
      id
    }
    orgSharpConfig {
      id
    }
    skill {
      id
    }
  }

  fragment LibraryItemTitle on LibraryItem {
    id
    name {
      en
    }
    type
    orgSharpConfig {
      id
    }
    orgPremiumContentConfig {
      premiumContentConfig {
        catalogPath {
          path {
            libraryItem {
              id
              name {
                en
              }
            }
          }
        }
      }
    }
  }

  fragment LibraryItemAvatar on LibraryItem {
    id
    type
    coverImage {
      ...CoverImage
    }
    trainingResource {
      id
      media {
        ...Media
      }
    }
    orgSharpConfig {
      id
    }
    orgPremiumContentConfig {
      id
      premiumContentConfig {
        id
        catalogPath {
          id
          path {
            id
            libraryItem {
              id
              coverImage {
                ...CoverImage
              }
            }
          }
        }
      }
    }
  }
`;

export const hasCoverImage = (
  coverImage: CoverImageFragment | null | undefined,
): boolean => {
  if (!coverImage) return false;
  return (
    !!coverImage.background || !!coverImage.emojiIcon || !!coverImage.imageUrls
  );
};
